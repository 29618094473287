import React, { Fragment, useState, useRef } from 'react';
import UnlockOutlined from "@ant-design/icons"
import { Row, Col, Drawer, Button, Input, message, Modal } from 'antd';
import { Form } from 'antd';
import { useResetPassword } from 'hooks/profile/profile';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';



const ResetView = () => {

    let navigate = useNavigate();

    // Reset

    const [resetOpen, setResetOpen] = useState(false);

    const resetShowDrawer = () => {
        setResetOpen(true);
    };

    const resetonClose = () => {
        setResetOpen(false);
        ref.current.resetFields();
    };


    const [form] = Form.useForm();
    const ref = useRef();

    const { mutate: Password, isLoading: Loading } = useResetPassword();

    const onFinish = (values) => {
        Password(values, {
            onSuccess: (item) => {
                if (item?.statusCode === 200) {
                    message.success(item?.message)
                    setTimeout(() => { showModals() }, 1000);
                    setTimeout(() => {
                        localStorage.removeItem('token_set');
                        localStorage.removeItem('service_role');
                        Cookies.remove('mail_id');
                        Cookies.remove('mail_otp');
                        navigate("/sign-in");
                    }, 5000);
                }
                else {
                    message.error(item?.message)
                }
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }


    // Out


    const [isModal, setIsModal] = useState(false);
    const showModals = () => {
        setIsModal(true);
    };
    const handleOks = () => {
        setIsModal(false);
    };
    const handleCancels = () => {
        setIsModal(false);
    };

    return (
        <Fragment>

            <Button onClick={resetShowDrawer} className="two-blue" block><UnlockOutlined />Reset Password</Button>

            <Drawer title="Add New Employee" placement="right" onClose={resetonClose} open={resetOpen}>
                <h1 className='drawer-head'>Reset Password</h1>
                <Form
                    form={form}
                    ref={ref}
                    onFinish={onFinish}
                    name="service_info"
                    layout="vertical"
                    className='drawer'>
                    <Form.Item label="Current Password" name="currentPassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter your Current Password!',
                            },
                        ]} >
                        <Input.Password placeholder=" Current Password" />
                    </Form.Item>
                    <Form.Item label="New Password" name="newPassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter your New Password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('currentPassword') !== value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('The current passwords & new password has been same!'));
                                },
                            }),
                        ]} >
                        <Input.Password placeholder="New Password" />
                    </Form.Item>
                    <Form.Item label="Confirm Password" name="confirmPassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please Confirm your Password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('new passwords and confirm password are not the same!'));
                                },
                            }),
                        ]}>
                        <Input.Password placeholder="Confirm Password" />
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col md={12} xs={24}>
                            <Form.Item>
                                <Button className="service-bluez" type="primary" loading={Loading} htmlType="submit" block>Update Password </Button>
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item>
                                <Button className="cancel-bluez" type="primary" onClick={resetonClose} block>Cancel </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            <Modal className='body-size' title="Basic Modal" open={isModal} onOk={handleOks} onCancel={handleCancels} width={1500} >
                <h1>Your company password has been updated</h1>
                <h1> Please login using the new password</h1>
            </Modal>

        </Fragment>
    )
}

export default ResetView;
