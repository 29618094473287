import React, { Fragment, useRef, useEffect } from 'react';
import { ArrowUpOutlined } from "@ant-design/icons";
import { BackTop, Form, Input, Button, Checkbox, message } from 'antd';
import { Link } from 'react-router-dom';
import Image from 'helpers/Image';
import { useFooter } from 'hooks/auth/Auth';
import "./style.css";

const Footer = () => {

    const [form] = Form.useForm();
    const Ref = useRef();

    const { mutate: footer } = useFooter();

    const onFinish = (values) => {
        footer(values, {
            onSuccess: (item) => {
                if (item?.statusCode === 200) {
                    message.success(item?.data)
                    Ref.current.resetFields();
                } else {
                    message.error(item?.message)
                }
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    const validateMessages = {
        required: 'Enter Email Address!',
        types: {
            email: 'Enter Your valid Email Address!',
        }
    }

    const scroll = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        // var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        (function () {
            let s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = 'https://embed.tawk.to/6344355a54f06e12d8996176/1gf170r4o';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <footer className="footer-container">
                <div className="footer-band">
                    <h5>Collaboration</h5>
                    <h1>Get Started with <br></br>ServiceView Today</h1>
                    <p>Ready to optimize your field service management? Sign up for<br></br>
                        ServiceView today and experience the benefits of the ultimate field <br></br>
                        service management software.
                    </p>
                </div>
                <div className="line"></div>
                {window?.location?.pathname === "/contact-us" ?
                    <div className="contact-buttonss" onClick={scroll}>
                        Contact Us<Image name="Right.png" className="icon-img" />
                    </div> :
                    <div className="contact-button">
                        <Link to="/contact-us">Contact Us<Image name="Right.png" className="icon-img" /></Link>
                    </div>}
            </footer>
            <footer className="main-footer">
                <div className="address">
                    <h5>Bangalore</h5>
                    <p>No. 114, Peninsula Park Elite,<br></br>
                        Doddathimasandra, Sarjapura,<br></br>
                        Bangalore</p>
                    <h5>Call</h5>
                    <p><a href="tel:98433 66587">+91 98433 66587</a></p>
                </div>
                <div className="email">
                    <h5>General questions</h5>
                    <p><a href="mailto:hello@serviceview.in">hello@serviceview.in</a></p>
                </div>
                <div className="sign-up">
                    <p>Subscribe to our newsletter to stay in touch with the <br></br> latest updates</p>
                    <Form form={form} onFinish={onFinish} validateMessages={validateMessages} ref={Ref} name="subscribe_info" layout="vertical" className="footer-form">
                        <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
                            <Input placeholder="abcd@gmail.com" />
                        </Form.Item>
                        <Button htmlType="submit" className="footer-btn-web">Sign Up</Button>
                        <Form.Item name="letterSubscription" valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                },
                            ]}


                        >
                            <Checkbox>I’m okay with getting emails and having that<br></br> activity tracked to improve my experience.</Checkbox>
                        </Form.Item>
                    </Form>
                </div>
            </footer >
            <footer className="mini-footer-web">
                <div className="mini-right">
                    <h1>© 2023 ServiceView. All rights reserved.</h1>
                </div>
                <div className="web-lines"></div>
                <div className="footer-link">
                    <Link to="/">Sitemap</Link>
                    <Link to="/faq">FAQ</Link>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms-condition">Terms Of Service</Link>
                </div>
            </footer>
            <BackTop>
                <button><ArrowUpOutlined /></button>
            </BackTop>
        </Fragment >
    )
}

export default Footer;