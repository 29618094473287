import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Drawer, Form, Upload, Input, Button, message } from 'antd';
import { useProfileUpdate } from 'hooks/profile/profile';
import Image from 'helpers/Image';
import PrivateImage from 'helpers/privateImage';
import "../products/common/style.css";



const EditView = (props) => {

    const { profileData, onCloser } = props;


    // Edit 

    const [open, setOpen] = useState(false);
    const [updateData, setUpdateData] = useState(null);

    const showDrawer = (val) => {
        setUpdateData(val);
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setUpdateData(null);
    };



    // Edit form start

    const [form] = Form.useForm();

    const { mutate: profileUpdate, isLoading: loading } = useProfileUpdate();

    const { Dragger } = Upload;
    const [files, setFiles] = useState(null);

    const onFinish = (values) => {
        const formData = new FormData();
        if (files !== null) {
            formData.append("organizationUserImage", files[0]?.originFileObj)
        }
        formData.append("organizationUserName", values.organizationUserName)
        formData.append("organizationUserContactNumber", values.organizationUserContactNumber)
        formData.append("organizationUserEmail", values.organizationUserEmail)

        profileUpdate(formData, {
            onSuccess: (response) => {
                if (response.statusCode === 200) {
                    message.success(response?.message);
                    onClose();
                    onCloser();
                }
                else {
                    message.error(response?.message);
                }
            },
            onError: (error) => {
                console.log(error);
            }
        });
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        return isJpgOrPng;
    };

    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: 'images/*',
        onChange(info) {
            const file = info.fileList[0];
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                return false
            }
            setFiles(info.fileList);
        },
        onDrop(e) {
            const file = e.dataTransfer.files[0];
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                return false
            }
            setFiles(e.dataTransfer.files)
        },
    };


    useEffect(() => {
        if (updateData !== null || updateData !== undefined) {
            form.setFieldsValue({
                "organizationUserName": updateData?.data?.organizationUserName,
                "organizationUserContactNumber": updateData?.data?.organizationUserContactNumber,
                "organizationUserEmail": updateData?.data?.organizationUserEmail,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData]);

    return (
        <Fragment>
            <Button onClick={() => showDrawer(profileData)} className="one-blue" block>Edit Profile</Button>
            <Drawer title="Add New Employee" placement="right" onClose={onClose} open={open}>
                <h1 className='drawer-head'>Edit Profile Details</h1>
                <Form
                    form={form}
                    name="profile_info"
                    onFinish={onFinish}
                    layout="vertical"
                    className='drawer'><br></br>
                    <Dragger {...uploadProps} maxCount={1} beforeUpload={beforeUpload} className="new-drag">

                        {files === null ?
                            updateData !== undefined && updateData?.data?.organizationUserImageUrl !== null ?
                                <PrivateImage privateUrl={updateData?.data?.organizationUserImageUrl} className="size_log" />
                                : <Image name="logo.png" className="size_log" /> :
                            <img
                                src={URL.createObjectURL(files[0]?.originFileObj)}
                                alt=".."
                                className="size_log"
                            />
                        }
                    </Dragger><br></br>
                    <Form.Item name="organizationUserName" label="Organisation Name" rules={[{ required: true, message: "Enter Your Organization Name!" }]}>
                        <Input maxLength={45} />
                    </Form.Item>
                    <Form.Item name="organizationUserContactNumber" label="Contact Number" rules={[
                        {
                            required: true,
                            message: "Enter Your Organization Contact Number!"
                        },
                        {
                            pattern: /^(?:\d*)$/,
                            message: "Value should contain just number",
                        }]}>
                        <Input prefix={"+91"} maxLength={10} />
                    </Form.Item>
                    <Form.Item name="organizationUserEmail" label="Email" rules={[
                        {
                            required: true,
                            type: "email",
                            message: "Enter Your Email!"
                        }]}>
                        <Input maxLength={40} />
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col md={12} xs={24}>
                            <Form.Item>
                                <Button className="service-blue" loading={loading} type="primary" htmlType="submit" block>Save Changes </Button>
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item>
                                <Button className="cancel-blue" onClick={onClose} block>Cancel </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </Fragment>
    )
}

export default EditView;
