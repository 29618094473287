import { useMutation, useQuery } from "react-query";
import * as auth from "services/authServices/AuthServices";

export const useAppLogin = () => {
    return useMutation((formData) => auth.appLogin(formData));
}

export const useForgotMail = () => {
    return useMutation((formData) => auth.forgotMail(formData));
}

export const useOtpMail = () => {
    return useMutation((formData) => auth.otpMail(formData));
}

export const useSetpassword = () => {
    return useMutation((formData) => auth.setPassword(formData));
}

export const useContact = () => {
    return useMutation((formData) => auth.contact(formData));
}

export const useFooter = () => {
    return useMutation((formData) => auth.footer(formData));
}

export const useFaq = () => {
    return useQuery("Faq", () => auth.faq(), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}