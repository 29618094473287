import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Image from 'helpers/Image';
import { useProfileList } from 'hooks/dashboard/dashboard';
import "./style.css"
import PrivateImage from 'helpers/privateImage';
import ProfileView from 'views/app/myProfile/view';
import { useStore } from "store";
import { Row, Col, Modal, Button, Form } from 'antd';

const CommonSidebar = () => {

    const { isRole } = useStore()
    let navigate = useNavigate();

    const isPathActive = (path) => {
        if (path === "/") return window.location.pathname === "/" ? " active" : "";
        else return window.location.pathname.startsWith("" + path) ? " active" : "";
    }

    const [isModal, setIsModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const showModalOpen = () => {
        setIsModal(true);
    };

    const onOkay = (e) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setIsModal(false);
            e.preventDefault();
            localStorage.removeItem('token_set');
            localStorage.removeItem('service_role');
            navigate("/home");
        }, 2000);
    };

    const onClose = () => {
        setIsModal(false);
    };

    const { data: profileData } = useProfileList();

    return (
        <Fragment>
            {isRole === "ORGANIZATION_ADMIN" && (
                <div className="service-view-sidebar" >
                    <div className="logo-head txt-center">
                        {profileData?.data?.organizationLogoImageUrl ?
                            <PrivateImage className="logo" privateUrl={profileData?.data?.organizationLogoImageUrl} /> :
                            <Image name="logo.png" className="logo" />}
                    </div>
                    <div className="hyper-link">
                        <ul>
                            <Link to="/dashboard"><li className={isPathActive("/dashboard")}><Image name="dashboard.png" />Dashboard</li></Link>
                            <Link to="/licence"><li className={isPathActive("/licence")}><Image name="licence.png" />Licence</li></Link>
                            <Link to="/employee"><li className={isPathActive("/employee")}><Image name="user.png" />Employee</li></Link>
                            <Link to="/customer"><li className={isPathActive("/customer")}><Image name="cust.png" />Customers</li ></Link>
                            {/* <Link to="/staff"><li className={isPathActive("/staff")}><Image name="cust.png" />Staff</li ></Link> */}
                            {/* <Link to="/product"><li className={isPathActive("/product")}><Image name="gem.png" />Products</li ></Link> */}
                            <Link to="/ticket"><li className={isPathActive("/ticket")}><Image name="tic.png" />Tickets</li ></Link>
                            {/* <Link to="/tools"><li className={isPathActive("/tools")}><Image name="check.png" />Tools</li ></Link> */}
                        </ul >
                        <ul className='footer-ul'>
                            <Link to="/setting">
                                <li className={isPathActive("/setting")}><Image name="set.png" />Settings</li></Link>
                            <li onClick={(e) => showModalOpen(e)}><Image name="log.png" />Logout</li>
                        </ul >
                        <ProfileView profileData={profileData} />
                    </div >
                </div >)
            }


            {isRole === "EMPLOYEE_STAFF" && (
                <div className="service-view-sidebar" >
                    <div className="logo-head txt-center">
                        {profileData?.data?.organizationLogoImageUrl ?
                            <PrivateImage className={"logo"} privateUrl={profileData?.data?.organizationLogoImageUrl} /> :
                            <Image name="logo.png" className="logo" />}
                    </div>
                    <div className="hyper-link">
                        <ul>
                            <Link to="/dashboard"><li className={isPathActive("/dashboard")}><Image name="dashboard.png" />Dashboard</li></Link>
                            <Link to="/employee"><li className={isPathActive("/employee")}><Image name="user.png" />Employee</li></Link>
                            <Link to="/customer"><li className={isPathActive("/customer")}><Image name="cust.png" />Customers</li ></Link>
                            {/* <Link to="/staff"><li className={isPathActive("/staff")}><Image name="cust.png" />Staff</li ></Link> */}
                            {/* <Link to="/product"><li className={isPathActive("/product")}><Image name="gem.png" />Products</li ></Link> */}
                            <Link to="/ticket"><li className={isPathActive("/ticket")}><Image name="tic.png" />Tickets</li ></Link>
                            {/* <Link to="/tools"><li className={isPathActive("/tools")}><Image name="check.png" />Tools</li ></Link> */}
                        </ul>
                        <ul className='footer-ul'>
                            <Link to="/setting">
                                <li className={isPathActive("/setting")}><Image name="set.png" />Settings</li></Link>
                            <li onClick={(e) => showModalOpen(e)}><Image name="log.png" />Logout</li>
                        </ul >
                        <ProfileView profileData={profileData} />
                    </div >
                </div >)
            }


            <Modal title="Basic Modal" open={isModal} onOk={onOkay} onCancel={onClose}>
                <h2 className='pop-up-text'>Are you sure to you want to log out  {profileData?.data?.organizationUserName}?</h2>
                <Form>
                    <Row gutter={[16, 16]} className="pop-up-check">
                        <Col md={12} xs={12}>
                            <Form.Item>
                                <Button className="no-btn" onClick={onClose} type="primary" block>No </Button>
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={12}>
                            <Form.Item>
                                <Button className="yes-btn" type="primary" loading={loading} onClick={onOkay} block>Yes </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Fragment >
    )
}

export default CommonSidebar

