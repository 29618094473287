import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';
import Sidebar from './utils/app/Sidebar';

import "../views/app/licence/common/style.css";
import "../views/app/employee/common/style.css";
import "../views/app/customers/common/style.css";
import "../views/app/staff/common/style.css";
import "../views/app/products/common/style.css";
import "../views/app/tickets/common/style.css";


function AppLayout() {

    const navigate = useNavigate();
    const token = localStorage.getItem("token_set");

    useEffect(() => {
        if (!token) {
            navigate('/sign-in')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    return (
        <div className="service-view error-page">
            <Row>
                <Col md={3} xs={0} className="service-sidebar">
                    <Sidebar />
                </Col>
                <Col md={21} xs={24} className="service-body">
                    <Outlet />
                </Col>
            </Row>
        </div>
    );
}

export default AppLayout;