import axios from "axios";
import { message } from "antd";

const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const TOKEN = localStorage.getItem("token_set");

const client = axios.create({
    baseURL: "https://api.serviceview.in/",
    timeout: TIMEOUT,
    headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${TOKEN}`,
    }
});

client.interceptors.request.use(function (config) {
    return config;
});
client.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.statusCode !== 401) {
            message.error(error?.response?.data?.message);
        } else if (error.response.statusCode !== 200) {
            message.error(error?.response?.data?.data);
        } else if (error.response.statusCode === 400) {
            message.error(error?.response?.data?.message);
        } else {
            console.error(error);
        }
        return Promise.reject(error);
    }
);



const ProfileUpdate = async (formData) => {
    const { data } = await client.put(`admin/editProfile`, formData);
    return data;
}

const ResetPassword = async (formData) => {
    const { data } = await client.put(`admin/changePassword`, formData);
    return data;
}

export {
    ProfileUpdate,
    ResetPassword
};