import { useQuery } from "react-query";
import * as imgApi from "services/awsImageServices/AwsImageServices";


export const useAwsImage = (imgPath) => {
    return useQuery(["AwsProfileImages", imgPath], () => imgApi.fetchAwsImage(imgPath), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
        refetchOnReconnect: true,
    });
}