import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";



const AccountSwitch = (props) => {

    const { profileData } = props;

    let navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [token, setToken] = useState(null);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getToken = (val) => {
        setToken(val);
    }

    useEffect(() => {
        if (token !== null) {
            localStorage.setItem("token_set", token?.token);
            localStorage.setItem("service_role", token?.organizationUserRole);
            navigate("/dashboard");
            setTimeout(() => window.location.reload(), 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return (
        <Fragment>
            <h6 className="service-red" onClick={showModal} block>Switch Account</h6>
            <Modal className="mode" title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="txt-left login-popup">
                    <h1>Select Organization</h1>
                </div>
                <Row className="org-main" gutter={[16, 16]}>
                    {profileData?.data?.organizationLogInResDtoList?.map((item, a) => (
                        <Col md={8} key={a} onClick={() => getToken(item)} className="org">
                            <Button block>
                                <p>{item?.organizationName?.charAt(0)}</p>
                                <h2>{item?.organizationName}</h2>
                            </Button>
                        </Col>
                    ))}
                    {/* <Col md={12} className='txt-right'>
                        <Button className="cancel-org">Cancel</Button>
                    </Col>
                    <Col md={12}>
                        <Button className="add-org">Submit</Button>
                    </Col> */}
                </Row>
            </Modal>
        </Fragment>
    )
}
export default AccountSwitch;