import axios from "axios";
import { message } from "antd";


// const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const TOKEN = localStorage.getItem("token_set");

const client = axios.create({
    baseURL: "https://api.serviceview.in/",
    timeout: TIMEOUT,
    headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${TOKEN}`,
    }
});

client.interceptors.request.use(function (config) {
    return config;
});
client.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.statusCode === 401) {
            message.error(error?.response?.message);
        } else {
            console.error(error);
        }
        return Promise.reject(error);
    }
);

const DashboardCount = async () => {
    const { data } = await client.get(`admin/dashboard-overview`);
    return data;
}

const TicketList = async () => {
    const { data } = await client.get(`admin/ticketsList?pageSize=8&pageNumber=0&status=NEW`);
    return data;
}

const OrderHistory = async () => {
    const { data } = await client.get(`admin/order`);
    return data;
}

const ProfileList = async () => {
    const { data } = await client.get(`admin/loginDetails`);
    return data;
}




export {
    DashboardCount,
    TicketList,
    OrderHistory,
    ProfileList
};