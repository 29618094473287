import React, { lazy, Suspense } from "react"
import { Routes, Route, Navigate } from 'react-router-dom';
import NotFound from "views/shared-component/404";

// Layouts
import WebLayout from "layouts/WebLayout";
import AuthLayout from "layouts/AuthLayout";
import AppLayout from "layouts/AppLayout";


// Authentication Routes
const SignIn = lazy(() => import('./views/auth/signIn'))
const Register = lazy(() => import('./views/auth/signUp'))
const MailVerify = lazy(() => import('./views/auth/signUp/mailVerify'))
const OtpVerify = lazy(() => import('./views/auth/signUp/otp'))
const Password = lazy(() => import('./views/auth/signUp/password'))
const ForgotPassword = lazy(() => import('./views/auth/forgotPassword/forgotPassword'))
const SetPassword = lazy(() => import('./views/auth/forgotPassword/setPassword'))

// Puplic Routes
const Home = lazy(() => import('./views/web/home'))
const Aboutus = lazy(() => import('./views/web/about-us'))
const Features = lazy(() => import('./views/web/features'))
const Faq = lazy(() => import('./views/web/faq'))
const Pricing = lazy(() => import('./views/web/pricing'))
const Privacy = lazy(() => import('./views/web/privacy'))
const TermsCondition = lazy(() => import('./views/web/terms'))
const ContactUs = lazy(() => import('./views/web/contact-us'))

// Admin Routes
const Dashboard = lazy(() => import('./views/app/dashboard'))

const PackageMonth = lazy(() => import('./views/app/licence/month'))
const PackageYear = lazy(() => import('./views/app/licence/year'))

const AllEmployee = lazy(() => import('./views/app/employee/listing/allEmployee'))
const ActiveEmployee = lazy(() => import('./views/app/employee/listing/activeEmployee'))
const InActiveEmployee = lazy(() => import('./views/app/employee/listing/inActiveEmployee'))

const AllCustomer = lazy(() => import('./views/app/customers/listing/allCustomer'))
const ActiveCustomer = lazy(() => import('./views/app/customers/listing/activeCustomer'))
const InActiveCustomer = lazy(() => import('./views/app/customers/listing/inActiveCustomer'))

const AllStaff = lazy(() => import('./views/app/staff/listing/allStaff'))
const ActiveStaff = lazy(() => import('./views/app/staff/listing/activeStaff'))
const InActiveStaff = lazy(() => import('./views/app/staff/listing/inActiveStaff'))

const AllProduct = lazy(() => import('./views/app/products/listing/allProduct'))
const ActiveProduct = lazy(() => import('./views/app/products/listing/activeProduct'))
const InActiveProduct = lazy(() => import('./views/app/products/listing/inActiveProduct'))

const AllTicket = lazy(() => import('./views/app/tickets/listing/allTicket'))
const ActiveTicket = lazy(() => import('./views/app/tickets/listing/activeTicket'))
const NewTicket = lazy(() => import('./views/app/tickets/listing/newTicket'))
const ClosedTicket = lazy(() => import('./views/app/tickets/listing/closedTicket'))
const HoldTicket = lazy(() => import('./views/app/tickets/listing/holdTicket'))
const RescheduledTicket = lazy(() => import('./views/app/tickets/listing/rescheduledTicket'))
const ScheduledTicket = lazy(() => import('./views/app/tickets/listing/scheduledTicket'))
const TicketDetails = lazy(() => import('./views/app/tickets/ticketDetails/ticketview'))

const AllTools = lazy(() => import('./views/app/tools/listing/allTools'))
const ExpiredTools = lazy(() => import('./views/app/tools/listing/expiredTools'))

const Customers = lazy(() => import('./views/app/settings/customer'))
const Company = lazy(() => import('./views/app/settings/company'))
const Gst = lazy(() => import('./views/app/settings/gst'))
const Unit = lazy(() => import('./views/app/settings/unit'))
const Department = lazy(() => import('./views/app/settings/department'))
const Email = lazy(() => import('./views/app/settings/email'))
const Reference = lazy(() => import('./views/app/settings/reference'))
const Terms = lazy(() => import('./views/app/settings/terms'))



function App() {
    return (
        <Suspense fallback={<div className="loader-img"><h1>Loading.....</h1></div>}>
            <Routes>
                <Route element={<WebLayout />}>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/about-us" element={<Aboutus />} />
                    <Route path="/features" element={<Features />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/privacy-policy" element={<Privacy />} />
                    <Route path="/terms-condition" element={<TermsCondition />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                </Route>

                <Route element={<AuthLayout />}>
                    <Route path="/sign-in" element={<SignIn />} />
                    <Route path="/domain-registration" element={<Register />} />
                    <Route path="/mail-verify" element={<MailVerify />} />
                    <Route path="/otp-verify" element={<OtpVerify />} />
                    <Route path="/password" element={<Password />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/set-password" element={<SetPassword />} />
                </Route>

                <Route element={<AppLayout />}>
                    <Route path="/dashboard" element={<Dashboard />} />

                    <Route path="/licence" element={<Navigate to="/licence/licence-month" />} />
                    <Route path="/licence/licence-month" element={<PackageMonth />} />
                    <Route path="/licence/licence-year" element={<PackageYear />} />

                    <Route path="/employee" element={<Navigate to="/employee/all-employee" />} />
                    <Route path="/employee/all-employee" element={<AllEmployee />} />
                    <Route path="/employee/active-employee" element={<ActiveEmployee />} />
                    <Route path="/employee/inactive-employee" element={<InActiveEmployee />} />

                    <Route path="/customer" element={<Navigate to="/customer/all-customer" />} />
                    <Route path="/customer/all-customer" element={<AllCustomer />} />
                    <Route path="/customer/active-customer" element={<ActiveCustomer />} />
                    <Route path="/customer/inactive-customer" element={<InActiveCustomer />} />

                    <Route path="/staff" element={<Navigate to="/staff/all-staff" />} />
                    <Route path="/staff/all-staff" element={<AllStaff />} />
                    <Route path="/staff/active-staff" element={<ActiveStaff />} />
                    <Route path="/staff/inactive-staff" element={<InActiveStaff />} />

                    <Route path="/product" element={<Navigate to="/product/all-product" />} />
                    <Route path="/product/all-product" element={<AllProduct />} />
                    <Route path="/product/active-product" element={<ActiveProduct />} />
                    <Route path="/product/inactive-product" element={<InActiveProduct />} />

                    <Route path="/ticket" element={<Navigate to="/ticket/all-ticket" />} />
                    <Route path="/ticket/all-ticket" element={<AllTicket />} />
                    <Route path="/ticket/new-ticket" element={<NewTicket />} />
                    <Route path="/ticket/active-ticket" element={<ActiveTicket />} />
                    <Route path="/ticket/closed-ticket" element={<ClosedTicket />} />
                    <Route path="/ticket/hold-ticket" element={<HoldTicket />} />
                    <Route path="/ticket/rescheduled-ticket" element={<RescheduledTicket />} />
                    <Route path="/ticket/scheduled-ticket" element={<ScheduledTicket />} />
                    <Route path="/ticket/ticket-details/:id" element={<TicketDetails />} />

                    <Route path="/tools" element={<Navigate to="/tools/all-tools" />} />
                    <Route path="/tools/all-tools" element={<AllTools />} />
                    <Route path="/tools/expired-tools" element={<ExpiredTools />} />

                    <Route path="/setting" element={<Navigate to="/setting/customer" />} />
                    <Route path="/setting/customer" element={<Customers />} />
                    <Route path="/setting/company-details" element={<Company />} />
                    <Route path="/setting/gst" element={<Gst />} />
                    <Route path="/setting/unit" element={<Unit />} />
                    <Route path="/setting/department" element={<Department />} />
                    <Route path="/setting/email" element={<Email />} />
                    <Route path="/setting/reference" element={<Reference />} />
                    <Route path="/setting/terms" element={<Terms />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    );
}

export default App;
