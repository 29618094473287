import axios from "axios";
import { message } from "antd";


// const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;

const client = axios.create({
    baseURL: "https://api.serviceview.in/",
    timeout: TIMEOUT,
    headers: {
        "content-type": "application/json",
    }
});

client.interceptors.request.use(function (config) {
    return config;
});
client.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.statusCode !== 401) {
            message.error(error?.response?.data?.message);
        } else if (error.response.statusCode !== 200) {
            message.error(error?.response?.data?.data);
        } else if (error.response.statusCode === 400) {
            message.error(error?.response?.data?.message);
        } else {
            console.error(error);
        }
        return Promise.reject(error);
    }
);

const appLogin = async (formData) => {
    const { data } = await client.post(`login/organization-admin/v3`, formData);
    return data;
}

const forgotMail = async (formData) => {
    const { data } = await client.post(`public/forgotPassword`, formData);
    return data;
}

const otpMail = async (formData) => {
    const { data } = await client.post(`public/verifyOtp`, formData);
    return data;
}

const setPassword = async (formData) => {
    const { data } = await client.put(`public/resetPassword`, formData);
    return data;
}

const contact = async (formData) => {
    const { data } = await client.post(`public/contactUs`, formData);
    return data;
}

const faq = async () => {
    const { data } = await client.get(`public/faq`);
    return data;
}

const footer = async (formData) => {
    const { data } = await client.post(`public/newsLetter`, formData);
    return data;
}


export {
    appLogin,
    contact,
    forgotMail,
    otpMail,
    setPassword,
    footer,
    faq
};