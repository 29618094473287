import React, { useState, Fragment } from 'react';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';
import Image from "helpers/Image"
import "./style.css"

const Header = () => {

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const isPathActive = (path) => {
        if (path === "/") return window.location.pathname === "/" ? "nav-active" : "";
        else return window.location.pathname.startsWith("" + path) ? "nav-active" : "";
    }



    return (
        <Fragment>
            <header className="service-view-header">
                <div className="web-logo">
                    <Link className={isPathActive("/")} to="/">
                        <Image name="logo.png" className="logo" />
                    </Link>
                </div>
                <div className="web-links">
                    <Link className={isPathActive("/")} to="/">Home</Link>
                    <Link className={isPathActive(`/features`)} to="/features" > Features</Link>
                    <Link className={isPathActive(`/about-us`)} to="/about-us">About Us</Link>
                    <Link className={isPathActive(`/contact-us`)} to="/contact-us"> Contact</Link>
                </div>
                <div className="mobile-view-only">
                    <h1 onClick={showDrawer}>Menu</h1>
                    <Drawer className='dra' placement="right" onClose={onClose} open={open}>
                        <ul onClose={onClose} style={{ marginLeft: "-30px" }}>
                            <li><Link onClick={onClose} className={isPathActive("/")} to="/">Home</Link></li>
                            <li><Link onClick={onClose} className={isPathActive("/features")} to="/features">Features</Link></li>
                            <li><Link onClick={onClose} className={isPathActive("/about-us")} to="/about-us">About Us</Link></li>
                            <li><Link onClick={onClose} className={isPathActive("/contact-us")} to="/contact-us">Contact</Link></li>
                            <li><Link onClick={onClose} to="/sign-in" className='one'>Login</Link></li>
                            <li><Link onClick={onClose} to="/domain-registration" className='two'>Register</Link></li>
                        </ul>
                    </Drawer>
                </div>
                <div className="web-auth">
                    <Link to="/pricing">Pricing</Link>
                    <Link to="/sign-in" ><span className="black-btn">Sign In</span></Link>
                </div>
            </header>
        </Fragment>
    )
}

export default Header;