import React, { Fragment } from "react";
import Issue from "../../../assets/4041.gif";

const NotFound = () => {
    return (
        <Fragment>
            <div className="page-not">
                <h1>Page Not Found.....</h1>
            </div>

            <div className="not-found">
                <img src={Issue} alt="..." />
            </div>
        </Fragment>

    )
}
export default NotFound