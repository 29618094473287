import React, { useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router-dom';
import AuthHeader from './utils/auth/authHeader';
import "../../src/views/auth/css/style.css";

function AuthLayout() {

    let navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("token_set")) {
            navigate('/dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="service-view-auth">
            <AuthHeader />
            <Outlet />
        </div>
    );
}

export default AuthLayout;