import { useQuery } from "react-query";
import * as api from "services/dashboardServices/DashboardServices";

export const useDashboardCount = () => {
    return useQuery("DashboardCount", () => api.DashboardCount(), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useTicketList = () => {
    return useQuery("TicketList", () => api.TicketList(), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useProfileList = () => {
    return useQuery("ProfileList", () => api.ProfileList(), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}


export const useOrderHistory = () => {
    return useQuery("OrderHistory", () => api.OrderHistory(), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

