import React, { Fragment, useState } from 'react';
import { Drawer, Row, Col, Avatar, Button } from 'antd';
import PrivateImage from 'helpers/privateImage';
import Image from 'helpers/Image'
import EditView from './edit';
import ResetView from './reset';
import AccountSwitch from './accountSwitch';


const ProfileView = (props) => {

    const { profileData } = props;

    // sidebar view 

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <ul className='profile-ul'>
                <Row gutter={[16, 8]} className="pointer" onClick={showDrawer}>
                    <Col md={7}>
                        {profileData?.data?.organizationUserImageUrl ?
                            <PrivateImage privateUrl={profileData?.data?.organizationUserImageUrl} className="avatar-logo" /> :
                            <Avatar>S</Avatar>
                        }
                    </Col>
                    <Col md={17}>
                        <p><b>{profileData?.data?.organizationUserName}</b></p>
                        <p>{profileData?.data?.organizationUserEmail}</p>
                    </Col>
                </Row>
            </ul>

            <Drawer title="Add New Employee" placement="right" onClose={onClose} open={open}>
                <h1 className='drawer-head'>Profile Details</h1>
                <Row className='domain-class'>
                    <Col md={24} xs={24} className="txt-center">
                        {profileData?.data?.organizationUserImageUrl ?
                            <PrivateImage privateUrl={profileData?.data?.organizationUserImageUrl} className="size_log" />
                            :
                            <Image name={"logo.png"} className={"size_log"} />}
                    </Col>
                    <Col md={24} xs={24} className="txt-center">
                        <p>{profileData?.data?.organizationDomainName || "serviceview"}<span>.serviceview</span></p>
                    </Col>
                    <Col md={24}>
                        {profileData?.data?.organizationLogInResDtoList?.length === 0 ? null :
                            <AccountSwitch profileData={profileData} />}
                    </Col>
                </Row>
                <Row className='edit-from'>
                    <Col md={12} xs={12}>
                        <h3>Organization Name</h3>
                    </Col>
                    <Col md={12} xs={12} className="txt-right">
                        <h4>{profileData?.data?.organizationUserName}</h4>
                    </Col>
                    <Col md={12} xs={12}>
                        <h3>Contact Number</h3>
                    </Col>
                    <Col md={12} xs={12} className="txt-right">
                        <h4>{profileData?.data?.organizationUserContactNumber || "-"}</h4>
                    </Col>
                    <Col md={12} xs={12}>
                        <h3>Email Address</h3>
                    </Col>
                    <Col md={12} xs={12} className="txt-right">
                        <h4>{profileData?.data?.organizationUserEmail}</h4>
                    </Col>
                </Row>




                <Row className="profile-bottom-btn" gutter={[16, 16]}>
                    <Col md={12} xs={24}>
                        <EditView profileData={profileData} onCloser={onClose} />
                    </Col>
                    <Col md={12} xs={24}>
                        <ResetView />
                    </Col>
                    <Col md={24} xs={24}>
                        <Button className="cancel-btn" onClick={onClose} block>Cancel</Button>
                    </Col>
                </Row>
            </Drawer>
        </Fragment>
    )
}

export default ProfileView;
