import { useMutation, useQueryClient } from "react-query";
import * as profile from "services/profileServices/ProfileServices";


export const useProfileUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => profile.ProfileUpdate(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('Setting');
            queryClient.refetchQueries('ProfileList');
        }
    });


}

export const useResetPassword = () => {
    return useMutation((formData) => profile.ResetPassword(formData));
}

