import axios from "axios";
import { message } from "antd";

const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const TOKEN = localStorage.getItem("token_set");


const baseURL = "https://api.serviceview.in/";
const client = axios.create({
    baseURL: "https://api.serviceview.in/",
    timeout: TIMEOUT,
    headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${TOKEN}`,
    }
});

client.interceptors.request.use(function (config) {
    return config;
});
client.interceptors.response.use(
    function (response) {

        return response;
    },
    function (error) {
        if (error.response.status === 401) {
        } else if (error.response.status !== 200) {
            message.error(error.response.data.data);
        } else {
            console.error(error);
        }
        return Promise.reject(error);
    }
);


const fetchAwsImage = async (imgPath) => {
    const data = await fetch(`${baseURL}getFileStream?path=${imgPath}`, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
        }
    })
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
    return data;
}


export {
    fetchAwsImage
};