import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import Header from './utils/web/Header/Header';
import Footer from './utils/web/Footer/Footer';

function WebLayout() {

    const navigate = useNavigate();
    const token = localStorage.getItem("token_set");

    useEffect(() => {
        if (!token) {
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    return (
        <div className="service-view">
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}

export default WebLayout;