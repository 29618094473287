import React from 'react';
import Image from "helpers/Image";
import { Link } from 'react-router-dom';
import "./style.css";

const AuthHeader = () => {
    return (
        <header className="auth-header">
            <Link to="/">
                <Image name="logo.png" className="logo" />
            </Link>
        </header>
    )
}
export default AuthHeader;
